import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  overflow: hidden;
  padding: 5rem 0 7.5rem;
  ${above('md')`
    padding: 7.5rem 0 10rem;
  `}
  ${above('lg')`
    padding-bottom: 12.5rem;
  `}
`

export const Form = styled.form`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(10)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: 53%;
  `}
`

export const Lines = styled.div`
  ${getTypeStyle('text')};
  ${above('md')`
    ${getTypeStyle('caption')};
  `}
  ${above('lg')`
    ${getTypeStyle('subtitle')};
  `}
`

export const Line = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 -0.25em -1.125em;
  flex-wrap: wrap;
  padding-top: 0.8rem;
  div {
    margin: 0 0.25em;
  }
`

export const Message = styled(Lines)`
  margin: 4rem 0 2.5rem;
`

export const MessageLabel = styled.div`
  ${getTypeStyle('smallText')};
  margin-bottom: -1rem;
  ${above('md')`
    ${getTypeStyle('blogText')};
  `}
  ${above('lg')`
    ${getTypeStyle('text')};
  `}
`

export const SuccessWrapper = styled.form`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(10)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const WrapperPhone = styled.div`
  width: auto;
  ${above('md')`
    width: 38%
  `}
`

export const WrapperInterest = styled.div`
  width: auto;
  ${above('md')`
    width: 52%
  `}
`

export const WrapperEmail = styled.div`
  width: auto;
  ${above('md')`
    width: 66%
  `}
`

export const WrapperName = styled.div`
  width: auto;
  ${above('md')`
    width: 62%
  `}
`
