import styled, { css } from 'styled-components'
import above from '@/utils/above'
import Base from '@/components/contents/shared/Contact/Contact'
import * as UI from '@/components/UI'

export const Contact = styled(Base)`
  padding-bottom: 4rem;
  ${above('md')`
    padding-bottom: 4rem;
  `}
  ${above('lg')`
    padding-bottom: 5rem;
  `}
`

export const InnerWrapper = styled.div`
  position: relative;
  &:last-of-type {
    padding-bottom: 5rem;
  }
`

export const ContactWrapper = styled.div`
  position: relative;
  overflow: hidden;

  ${p =>
    p.isEvents &&
    css`
      ${above('md')`
       padding-top: 2rem;
     `}
      ${above('lg')`
        padding-top: 3rem;
      `}
    `}
`

export const StyledImage = styled(UI.Image)`
  position: relative;
  width: 100%;
  z-index: 20;
  margin: 0 auto;
  ${above('md')`
    width: ${p => p.width}px;
  `}
  ${above('lg')`
    position: absolute;
    padding-top: 3rem;
    top:-100px; 
    left:50%;
   
  `}
`
